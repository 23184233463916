// font from CDN
// @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700")

///mixins
@import "styles/mixins/fontface";

//fonts
@include fontFace('rockwell', '/assets/fonts/rockwell');
@include fontFace('cera-pro', '/assets/fonts/cera-pro-regular');

// theme
@import "../node_modules/bootstrap/scss/functions";
@import "styles/bootstrapvariables";
@import "styles/variables";

// vendor modules
@import "../node_modules/bootstrap/scss/bootstrap";

// stylesheets containers
@import "styles/containers/Root";
@import "styles/containers/App";
@import "styles/containers/Home";
@import "styles/containers/Dashboard";
@import "styles/containers/Account";
@import "styles/containers/FourOFour";

//stylesheets components
@import "components/About/About";
@import "components/FieldInput/FieldInput";
@import "components/FileInput/FileInput";
@import "components/LoadingContent/LoadingContent";
@import "components/Login/Login";
@import "components/Navbar/Navbar";
@import "components/OfflineBar/OfflineBar";
@import "components/PasswordRecovery/PasswordRecovery";
@import "components/PasswordReset/PasswordReset";
@import "components/Register/Register";
@import "components/Topping/Topping";

body {
  margin: 0;
  padding: 0;
  //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.btn-circle-float {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 999;
  i {
    transform: translateY(15%); } }


body {
  font-family: 'cera-pro'; }
