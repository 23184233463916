.navigationbar {
  z-index: 999;
  height: 70px;
  position: fixed;
  width: 100%;
  transition: background-color 500ms linear;
  &__user {
    @include media-breakpoint-up(sm) {
      order: 3; }
    border-left: 1px solid $navbar-dark-color; }
  &--sticky {
    background-color: $dark; }
  .navbar {
    justify-content: space-around; }
  .bright-bg-wrapper {
    background: $primary; } }
.navplaceholder {
  height: 70px;
  width: 100%;
  background-color: $dark;
  position: fixed;
  top: 0;
  z-index: 990; }
.navplaceholder:after {
  position: relative;
  height: 70px; }
.recipedisplay {
  padding-top: 8rem; }

.navbar-dark .navbar-nav .dropdown-menu.show .nav-link {
  color: $body-color; }

.navbar-collapse {
  @include media-breakpoint-down(xs) {
    margin: 0.5rem -1rem;
    background-color: $white;
    padding: 1rem;
    .navbar-nav {
      .nav-link {
        color: rgba(0, 0, 0, 0.5); }
      .show > .nav-link, .active > .nav-link, .nav-link.show, .nav-link.active, .nav-link:focus {
        color: $dark; } } } }

.navbar-nav .dropdown-menu .nav-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;


  a.nav-link {}
  color: #28dd65 !important; }
a {
  color: white; }

a:hover {
  color: white; }

.wisag__container {
  clip-path: ellipse(68% 96% at 68% 96%);
  background: #d8f4ee;
  padding: 0.2em;
  &__text {
    color: $gray-700;
    font-size: 10px; } }




