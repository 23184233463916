.Topping {
  .topping-img {
    max-height: 250px;
    object-fit: cover; }
  .nav-link.active {
    font-weight: bold;
    background-color: #4b9b8b;
    border: 0!important;
    color: #ffff; }
  .topping-tab {
    border: 0!important;
    color: #ffff;
    font-size: 21px !important; }
  .active {
    background-color: #4b9b8b;
    font-weight: bold;
    font-size: 21px !important; }
  .nav-link {
    font-size: 21px; } }
